import React, { Component } from 'react'
import styled from 'styled-components'

const Title = styled.div`
  display: flex;
  justify-content: center;

  h1 {
    font-size: 40px;
  }
  p {
    width: 40%;
  }
`

class TextTitle extends Component {
  render() {
    return(
      <Title>
        <h1>{this.props.title}</h1>
      </Title>
    )
  }
}

export default TextTitle;