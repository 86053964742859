import React from "react"

import Layout from "../Components/layout.js"
import SEO from "../Components/seo.js"

import TextTitle from '../Components/TextTitle.jsx'
import Row from '../Components/Rows.jsx'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Row>
      <TextTitle
        title={'404 Not Found'}
      />
    </Row>
  </Layout>
)

export default NotFoundPage
